import {Component, HostListener, Inject, PLATFORM_ID, ViewChild} from '@angular/core';
import {
  AppsService,
  AuthService,
  CartFormItem,
  CheckoutField,
  CheckoutFieldSubType,
  CheckoutFieldType,
  CommonService,
  CountDown,
  Discount,
  EventTypeEnum,
  ExpressOrder,
  extractErrorMessagesFromResponse,
  MediaSize,
  MediaType,
  NeoUpsellModalComponent,
  Pageable,
  Product,
  ProductDiscount,
  ProductService,
  ProductVariation,
  ProductVariationForm,
  ShopyanCartHelperService,
  ShopyanToastrService,
  StorageService,
  StoreService,
  Upsell,
  UpsellPage,
  UpsellProductForm,
  UpsellService,
  urlMedia,
  VariationDiscount,
  VariationOptionType
} from "@app/_shared";
import {Subscription} from "rxjs";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DomSanitizer, makeStateKey, TransferState} from "@angular/platform-browser";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ShopyanSectionComponent} from "@app/_shared/core/shopyan-section.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {isPlatformServer} from "@angular/common";
import {ConversionService} from "@app/_shared/service/conversion.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  template: ''
})
export abstract class ShopyanProductComponent extends ShopyanSectionComponent {

  subscription: Subscription = new Subscription();

  @ViewChild('slickModal')
  slickModal: any;

  loading: boolean;

  // Product
  product: Product;
  description: any;
  price: number;
  basicPrice: number;

  // Discounts
  discounts: Discount[] = [];

  // Media
  media: any[] = [];
  selectedMedia: any;

  // Countdown
  countDown: CountDown;

  // Forms
  expressFG: FormGroup;
  checkoutLabel: any;
  checkoutFields: CheckoutField[];

  checkoutFieldType = CheckoutFieldType;
  quantity: number = 1;
  selectedVariation: string = '';
  formLoading = false;
  isProductPage = true;

  selectedOptions: string[] = [];

  maxQuantity: number;

  variationOptionType = VariationOptionType;

  protected constructor(protected translate: TranslateService,
                        private _sanitizer: DomSanitizer,
                        private upsellService: UpsellService,
                        private commonService: CommonService,
                        private productService: ProductService,
                        private storeService: StoreService,
                        private router: Router,
                        private storageService: StorageService,
                        private authService: AuthService,
                        private dialog: MatDialog,
                        protected basicHelperService: ShopyanCartHelperService,
                        private basicToastr: ShopyanToastrService,
                        public formBuilder: FormBuilder,
                        private applicationsService: AppsService,
                        private http: HttpClient,
                        private conversionService: ConversionService,
                        private transferState: TransferState,
                        @Inject(PLATFORM_ID) protected platformId: any,
                        private deviceDetectorService: DeviceDetectorService) {
    super();
  }


  init(): void {
    this.isProductPage = this.buildMode || this.storeService.currentPath.indexOf('/product/') >= 0;

    if (this.buildMode) {
      let landingPageSlug = this.storageService.getData('landing_page_slug');
      if (landingPageSlug) {
        this.findProductByLandingPageSlug(JSON.parse(landingPageSlug));
      } else {
        this.getRandomProduct();
      }
      this.findCheckoutFields();

    } else if (isPlatformServer(this.platformId)) {
      this.loading = true;
      this.initProduct();
      this.findCheckoutFields();
      if (!this.isLandingComponent()) {
        this.initCountDown();
      }

    } else {
      this.product = this.transferState.get(makeStateKey(this.data.code + '-product'), null as any);
      if (!this.product) {
        this.router.navigateByUrl(`/not-found`).then();
      } else {
        this.initProductData();
        const productDiscount: ProductDiscount = this.transferState.get(makeStateKey(this.data.code + '-discounts'), null as any);
        this.initDiscounts(productDiscount);
        this.checkoutLabel = this._sanitizer.bypassSecurityTrustHtml(this.transferState.get(makeStateKey(this.data.code + '-checkoutLabel'), null as any));
        this.checkoutFields = this.transferState.get(makeStateKey(this.data.code + '-checkoutFields'), null as any);
        if (this.checkoutFields && this.checkoutFields.length > 0) {
          this.initCheckoutForm();
        }
        let countDown = this.transferState.get(makeStateKey(this.data.code + '-countDown'), null as any);
        if (countDown && (!countDown.products || countDown.products.length === 0 || countDown.products.indexOf(this.product.id) >= 0)) {
          this.countDown = countDown;
        }
        if (!this.isProductPage || !this.isLandingComponent()) {
          this.subscription.add(this.conversionService.sendViewContentEvent().subscribe());
          this.sendProductEvent(this.product.id);
        }
      }
    }

  }

  abstract isLandingComponent(): boolean;

  /**
   * Init product
   */
  initProduct(): void {
    let slug = encodeURI(this.storeService.currentPath.split('/')[this.storeService.currentPath.split('/').length - 1]);
    if (this.isProductPage) {
      this.findProductBySlug(slug);
    } else {
      this.findProductByLandingPageSlug(slug);
    }
  }


  /**
   * Find product bu slug
   * @param slug
   * @private
   */
  private findProductByLandingPageSlug(slug: string): void {
    this.subscription.add(this.storeService.getProductIdByLandingSlug(slug).subscribe((response: any) => {
      this.findProductById(response);
    }));
  }

  /**
   * Find product
   */
  findProductById(productId: string): void {
    if (!productId) {
      this.router.navigateByUrl(`/not-found`).then();
    }
    this.loading = true;
    this.subscription.add(this.productService.findProduct(productId, false).subscribe((response: any) => {
      this.findProductManageResponse(response);
    }));
  }

  /**
   * Find product by slug
   * @param slug
   */
  findProductBySlug(slug: string): void {
    if (!slug) {
      this.router.navigateByUrl(`/not-found`).then();
    }
    this.loading = true;
    this.subscription.add(this.productService.findProductBySlug(slug).subscribe((response: any) => {
      this.findProductManageResponse(response);
    }));
  }

  findProductManageResponse(response: any): void {
    this.loading = false;
    this.product = response;
    this.transferState.set(makeStateKey(this.data.code + '-product'), this.product as any);
    this.initProductData();
    this.findDiscounts();
  }

  /**
   * Filter products for build mode
   */
  getRandomProduct(): void {
    this.loading = true;
    let pageable = new Pageable();
    pageable.size = 1;
    this.subscription.add(this.productService.filterProducts(pageable).subscribe((response: any) => {
      if (response && response.data && response.data.length > 0) {
        this.loading = false;
        this.product = response.data[0];
        this.transferState.set(makeStateKey(this.data.code + '-product'), this.product as any);
        this.initProductData();
        this.findDiscounts();
      }
    }));
  }

  /**
   * Init product data
   */
  initProductData(): void {
    if (this.product) {

      // Keep to product name in store so it can be used by whatsapp app
      this.storageService.saveData('current_product_name', this.product.name);

      this.initMedia();
      this.initVariation();
      if (!this.deviceDetectorService.isMobile()) {
        this.initDescription();
      }
    }
  }

  initVariation(): void {
    if (this.product.options) {
      this.selectedOptions = new Array(this.product.options.length).fill('');
    }

    let variation;
    if (this.product.variations?.length > 0) {
      const availableVariations = this.product.variations.filter(variation => !variation.disabled && (this.product.disableOutOfStock || variation.quantity > 0));
      variation = availableVariations?.find(v => v.asDefault);
      if (!variation && availableVariations && availableVariations.length > 0) {
        variation = availableVariations?.reduce((minVariation, variation) => {
          return variation.price < minVariation.price ? variation : minVariation;
        });
      }
      if (variation) {
        this.selectedVariation = variation.id;
        this.price = variation.price;
        this.basicPrice = variation.basicPrice;
        this.discounts = variation.discounts;
        variation.optionValues.forEach((ov: string, index: number) => {
          this.selectedOptions[index] = ov;
        });
        if (!this.product.disableOutOfStock && variation.quantity && variation.quantity > 0) {
          this.maxQuantity = variation.quantity;
        }
      }
    }
    if (this.product.pricing && !variation) {
      this.price = this.product.pricing.price;
      this.basicPrice = this.product.pricing.basicPrice;
      if (!this.product.disableOutOfStock && this.product.stock && this.product.stock > 0) {
        this.maxQuantity = this.product.stock;
      }
    }
  }

  sendProductEvent(productId: string) {
    if (!this.buildMode) {
      // Browser only - no private api call
      let url = `${environment.pubicStoreApiUrl}/events`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'text' as 'json'
      };
      this.http
        .post(url, {
          productId: productId,
          eventType: EventTypeEnum.VISIT_PRODUCT
        }, httpOptions).subscribe();
    }
  }

  /**
   * Find discounts
   */
  findDiscounts(): void {
    const apiUrl = isPlatformServer(this.platformId) ? environment.privateDiscountApiUrl : environment.pubicDiscountApiUrl
    let url = `${apiUrl}/discounts/products`;

    if (this.authService.isAuthenticated()) {
      url = `${apiUrl}/api/discounts/products`;
    }

    let productVariationForm = new ProductVariationForm();
    productVariationForm.id = this.product.id;
    productVariationForm.variations = this.product.variations?.map((variation: ProductVariation) => variation.id);
    this.subscription.add(this.commonService.create(url, [productVariationForm]).subscribe((response: any) => {
      if (response) {
        this.initDiscounts(response[0]);
        this.transferState.set(makeStateKey(this.data.code + '-discounts'), response[0] as any);
      }
    }));
  }

  /**
   * Init discounts
   */
  initDiscounts(productDiscount: any): void {
    if (productDiscount) {
      this.discounts = productDiscount.discounts;
      this.product.discounts = this.discounts;
      const map = new Map(productDiscount.variations?.map((obj: VariationDiscount) => [obj.id, obj.discounts]));
      this.product.variations?.forEach((variation: ProductVariation) => variation.discounts = map.get(variation.id) as Discount[]);
    }
  }

  /**
   * Find checkout fields
   */
  findCheckoutFields(): void {
    this.subscription.add(this.storeService.getCheckoutFields().subscribe((response: any) => {
      if (response) {
        this.checkoutLabel = this._sanitizer.bypassSecurityTrustHtml(response.title);
        this.checkoutFields = response.fields;
        if (this.checkoutFields && this.checkoutFields.length > 0) {
          this.initCheckoutForm();
        }
        this.transferState.set(makeStateKey(this.data.code + '-checkoutLabel'), response.title as any);
        this.transferState.set(makeStateKey(this.data.code + '-checkoutFields'), response.fields as any);
      }
    }));
  }

  /**
   * init media
   */
  initMedia(): void {
    if (!this.isLandingComponent() && this.product.youtubeIds?.length > 0) {
      this.product.youtubeIds.forEach(youtubeID => {
        const tmpURL = 'https://youtube.com/embed/' + youtubeID;
        const mediaItem = {
          isVideo: true,
          thumbnail: 'http://img.youtube.com/vi/' + youtubeID + '/default.jpg',
          link: this._sanitizer.bypassSecurityTrustResourceUrl(tmpURL)
        };
        this.media.push(mediaItem);
      });
    }

    if (this.product.medias?.length > 0) {
      this.product.medias.forEach((item: any) => {
        const mediaItem = {
          isVideo: false,
          link: this.urlProductMedia(item)
        };
        this.media.push(mediaItem);
      });
    }

    if (this.product.imageLinks?.length > 0) {
      this.product.imageLinks.forEach((imageLink: any) => {
        const mediaItem = {
          isVideo: false,
          link: imageLink
        };
        this.media.push(mediaItem);
      });
    }

    this.product.variations?.forEach((variation: any) => {
      if (variation.media) {
        const mediaItem = {
          isVideo: false,
          link: this.urlVariantMedia(variation.media)
        };
        this.media.push(mediaItem);
      }
    });

    if (this.media?.length > 0) {
      this.selectedMedia = this.media[0];
    }

    this.initGallery();
  }

  protected abstract initGallery(): void;


  /**
   * Select option (tag mode)
   * @param optionValue
   * @param index
   */
  selectOption(optionValue: string, index: number): void {
    if (this.selectedOptions[index] === optionValue) {
      this.selectedOptions[index] = '';
    } else {
      this.selectedOptions[index] = optionValue;
    }

    let variation = this.product.variations
      .find(variation => !variation.optionValues.some((item, index) => this.selectedOptions[index] !== item));

    if (variation) {
      this.selectedVariation = variation.id;
      this.changeVariation();
    } else {
      this.selectedVariation = '';
    }
  }

  /**
   * Is option disabled/ Not available
   * @param optionValue
   * @param index
   */
  isOptionDisabled(optionValue: string, index: number): boolean {
    let selected = [...this.selectedOptions];
    selected[index] = optionValue;

    return !this.product.variations
      .filter(variation => !variation.disabled && (this.product.disableOutOfStock || variation.quantity > 0))
      .some(v => !v.optionValues.some((item, i) => selected[i] !== '' && selected[i] !== item));

  }

  /**
   * Change variation
   */
  changeVariation() {
    const variation = this.product.variations.find(v => v.id === this.selectedVariation);
    if (variation) {
      this.price = variation.price;
      this.basicPrice = variation.basicPrice;
      this.discounts = variation.discounts;
      if (!this.product.disableOutOfStock && variation.quantity && variation.quantity > 0) {
        this.maxQuantity = variation.quantity;
        if (this.quantity > this.maxQuantity) {
          this.quantity = this.maxQuantity;
        }
      }
      if (variation.media) {
        this.selectedMedia = {
          isVideo: false,
          link: this.urlVariantMedia(variation.media)
        };

        if (this.slickModal) {
          const target = this.media.find(m => m.link === this.selectedMedia.link);
          if (target) {
            const index = this.media.indexOf(target);
            console.log(index);
            if (index >= 0) {
              this.slickModal.slickGoTo(index);
            }
          }
        }

      }
    }

  }

  /**
   * Init checkout form
   */
  initCheckoutForm(): void {

    this.checkoutFields = this.checkoutFields.filter(item => !item.disabled);
    if (this.checkoutFields.length === 0) {
      return;
    }

    this.expressFG = this.formBuilder.group({
      fields: this.formBuilder.array([])
    });
    const fieldsArray = this.expressFG.get('fields') as FormArray;
    this.checkoutFields.forEach(item => {

      let validators = [];
      if (item.required) {
        validators.push(Validators.required);
      }

      if (item.type === CheckoutFieldType.MAIL) {
        validators.push(Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'));
      }

      let defaultValue = item.defaultValue;

      if (!defaultValue && item.subType && item.subType === CheckoutFieldSubType.SELECT) {
        defaultValue = '';
      }

      fieldsArray.push(this.formBuilder.group({
        id: [item.id],
        value: [defaultValue, validators]
      }));
    });

  }

  /**
   * Submit express checkout
   */
  submitExpressCheckout(): void {
    this.expressFG.markAllAsTouched();
    if (!this.expressFG.valid) {
      this.basicToastr.error(this.translate.instant("COMMON.MESSAGE.FILL_REQUIRED_FIELDS"));
      document.getElementById('cod-form-anchor')?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      return;
    }

    if (this.quantity <= 0) {
      this.basicToastr.error(this.translate.instant("PRODUCT.MESSAGE.QUANTITY"));
      document.getElementById('product-quantity-anchor')?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      return;
    }

    if (!this.selectedVariation && this.product.variations && this.product.variations.length > 0) {
      this.basicToastr.error(this.translate.instant("PRODUCT.MESSAGE.OPTION"));
      document.getElementById('product-options-anchor')!.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      return;
    }

    let expressOrder = new ExpressOrder();
    expressOrder.formFields = [];
    Object.assign(expressOrder.formFields, this.expressFG.get('fields')?.value);

    let cartFormItem = new CartFormItem();
    cartFormItem.product = this.product.id;
    cartFormItem.variation = this.selectedVariation;
    cartFormItem.quantity = this.quantity;
    expressOrder.items = [cartFormItem];

    this.formLoading = true;
    this.proposeUpsells(false, expressOrder);

  }

  /**
   * Submit classic checkout
   */
  submitClassicCheckout(): void {
    if (this.addToCartCommon()) {
      this.formLoading = true;
      this.proposeUpsells(true, null);
    }
  }

  /**
   * Add to cart
   */
  addToCart(): void {
    if (this.addToCartCommon()) {
      this.proposeUpsells(false, null);
    }
  }

  /**
   * Add to cart common method
   */
  private addToCartCommon(): boolean {
    if (this.quantity <= 0) {
      this.basicToastr.error(this.translate.instant("PRODUCT.MESSAGE.QUANTITY"));
      document.getElementById('product-quantity-anchor')!.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      return false;
    }

    if (!this.selectedVariation && this.product.variations && this.product.variations.length > 0) {
      this.basicToastr.error(this.translate.instant("PRODUCT.MESSAGE.OPTION"));
      document.getElementById('product-options-anchor')?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      return false;
    }

    this.basicHelperService.addItemToCart(this.product, this.quantity, this.selectedVariation);
    return true;
  }


  refreshQuantity(quantity: number) {
    this.quantity = quantity;
  }

  isProductWishListChecked(productId: string) {
    return this.basicHelperService.isProductWishListChecked(productId);
  }

  toggleToWishList(productId: string): void {
    this.basicHelperService.toggleToWishList(productId);
  }

  urlProductMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.PRODUCT, MediaSize.original, media);
  }

  urlVariantMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.VARIANT, MediaSize.original, media);
  }

  urlVariantSmallMedia(media: string): string {
    return urlMedia(environment.mediaURL + "/", this.storeID, MediaType.VARIANT, MediaSize.small, media);
  }

  /**
   * Init countdown
   */
  initCountDown(): void {
    this.subscription.add(this.applicationsService.getCountDownData().subscribe((response: any) => {
      this.countDown = response;
      this.transferState.set(makeStateKey(this.data.code + '-countDown'), this.countDown as any);
    }));
  }

  public getSmallImagePath(source: any): string {
    if (source.indexOf('/original/') >= 0) {
      return source.replace('/original/', '/small/');
    } else if (source.indexOf('/medium/') >= 0) {
      return source.replace('/medium/', '/small/');
    } else {
      return source.toString();
    }
  }

  /**
   * Find upsells to propose
   * @param classicCheckout
   * @param expressOrder
   * @private
   */
  private proposeUpsells(classicCheckout: boolean, expressOrder: ExpressOrder | null): void {

    let upsellProduct = new UpsellProductForm();
    upsellProduct.product = this.product.id;

    if (this.selectedVariation) {
      upsellProduct.variations = [];
      upsellProduct.variations.push(this.selectedVariation);
    }

    this.subscription.add(this.upsellService.findUpsells([upsellProduct], UpsellPage.PRODUCT).subscribe((response: any) => {
      if (response && response.length > 0) {
        this.openUpsellModal(response, UpsellPage.PRODUCT, this.storeID, classicCheckout, expressOrder);
      } else {
        this.manageAfterUpsell(classicCheckout, expressOrder);
      }
    }));
  }

  /**
   * Show upsells modal
   * @param upsells
   * @param page
   * @param storeId
   * @param classicCheckout
   * @param expressOrder
   * @private
   */
  private openUpsellModal(upsells: Upsell[], page: UpsellPage, storeId: string, classicCheckout: boolean, expressOrder: ExpressOrder | null): void {
    const config = {
      width: '450px',
      autoFocus: false,
      panelClass: ['yan-dialog'],
      data: {
        upsells: upsells,
        page: page,
        storeId: storeId,
        express: expressOrder != null
      }
    };
    const dialogRef = this.dialog.open(NeoUpsellModalComponent, config);
    this.subscription.add(dialogRef.afterClosed().subscribe((result: any) => {
      this.manageAfterUpsell(classicCheckout, expressOrder, result);
    }));
  }

  /**
   * After proposing an upsell or not, do the following
   * @param classicCheckout
   * @param expressOrder
   * @param upsellCartFormItem
   */
  manageAfterUpsell(classicCheckout: boolean, expressOrder: ExpressOrder | null, upsellCartFormItem?: CartFormItem) {
    if (classicCheckout) {
      this.router.navigateByUrl(`/checkout`).then();

    } else if (expressOrder) {

      if (upsellCartFormItem) {
        expressOrder.items.push(upsellCartFormItem);
      }

      let url = `${isPlatformServer(this.platformId) ? environment.privateOrdersApiUrl : environment.pubicOrdersApiUrl}/checkout/express`;
      this.subscription.add(this.commonService.create(url, expressOrder).subscribe({
        next: (response: any) => {
          this.basicToastr.success(this.translate.instant("CHECKOUT.MESSAGE.SUCCESS"));
          this.router.navigateByUrl(`/thank-you?order=${response.orderCode}`).then();
        },
        error: (error: any) => {
          this.formLoading = false;
          this.basicToastr.error(extractErrorMessagesFromResponse(error));
        }
      }));
    }
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    this.initDescription();
  }


  initDescription(): void {
    if (!this.description && this.product && this.product.description) {
      let description = this.product.description.replace(new RegExp('/product/original/', 'g'), '/product/medium/');
      description = description.replace(new RegExp('<img ', 'g'), '<img priority fill alt="store" ');
      this.description = this._sanitizer.bypassSecurityTrustHtml(description);
    }
  }

}


